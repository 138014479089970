<template>
  <div class="box">
     <router-link
        v-if="$can('admin/shop-temp/create')"
        :to="{ path: 'shop-temp/create' }"
      >
        <el-button
          type="primary"
          size="medium"
        >
          新建素材
        </el-button>
      </router-link>
      <div
      class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="请输入菜品关键字"
          v-model="product_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
      height="70vh"
    >
      <el-table-column
      width="80"
      label="序号"
      prop="id">
      </el-table-column>
      <el-table-column
      width="150"
      label="菜品名称"
      prop="product_name">
      </el-table-column>
      <el-table-column
      label="描述"
      width="300px"
      prop="product_describe">
      </el-table-column>
       <el-table-column
       width="180px"
      label="成份"
      prop="product_component">
      </el-table-column>
       <el-table-column
       width="100"
      label="详情封面"
      prop="cover_map">
      <template slot-scope="scope">
        <img class="shop-img" @click="dialog(scope.row.cover_map)"  :src="scope.row.cover_map" alt="">
      </template>
      </el-table-column>
       <el-table-column
       width="100"
      label="列表封面"
      prop="list_map">
      <template slot-scope="scope">
        <img class="shop-img" @click="dialog(scope.row.list_map)"  :src="scope.row.list_map" alt="">
      </template>
      </el-table-column>
      <el-table-column
        width="115px"
        label="是否外卖"
        prop="is_wm">
        <template slot-scope="scope">
         <el-switch
          @change="changeWm(scope.row)"
          v-model="scope.row.is_wm"
          inactive-color="#ddd"
          :active-text="scope.row.is_wm == 1?'是':'否'"
        >
        </el-switch>
      </template>
      </el-table-column>

      <el-table-column
        width="190"
        label="操作"
        fixed="right"
      >
        <template slot-scope="scope">
           <el-button
            v-if="$can('admin/shop-temp/pei-zhi')"
            class="button mr-2"
            size="mini"
            type="danger"
            @click.native="handleEdit(scope.row)"
            plain
          >配置</el-button>
          <el-dropdown trigger="click">
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
               <el-dropdown-item
                v-if="$can('admin/shop-temp/update')"
                @click.native="openModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <!-- <el-dropdown-item
                v-if="$can('admin/shop-temp/update')"
                @click.native="handleModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item> -->
              <el-dropdown-item
                v-if="$can('admin/shop-temp/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    >
    </page>
    <!-- 图片 -->
     <el-dialog
      :visible.sync="dialogVisible"
      width="400"
      >
      <div class="dialog-img">
        <img :src="dialogImgPath" alt="">
      </div>
     </el-dialog>
     <!-- 编辑 -->
    <editGo ref="ces" v-on:editOk="searchGo(true)"></editGo>
  </div>
</template>

<script>
import ShopTempService from '@admin/services/ShopTempService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'
import EditGo from './Edit'

export default {
  name: 'ShopTempIndex',
  components: {
    Page, EditGo
  },
  data () {
    return {
      dialogImgPath: '',
      dialogVisible: false,
      loading: true,
      function: function (param) {
        return ShopTempService.all(param)
      },
      product_name: '',
      delete: function (param) {
        return ShopTempService.delete(param)
      }
    }
  },
  mixins: [pagination],
  methods: {
    openModelEdit (item) {
      this.$refs.ces.createdGo(item)
    },
    dialog (imgPath) {
      this.dialogVisible = true
      this.dialogImgPath = imgPath
    },
    async searchGo (isSave = false) {
      if (this.isSave === false) {
        this.page = 1
      }
      let param = {
        product_name: this.product_name
      }
      this.where = param
      await this.getList(param)
    },
    handleEdit (model) {
      let url = this.$route.path + '/pei-zhi'
      this.$router.push({ path: url, query: { id: model.id } })
    },
    async changeWm (row) {
      let param = {
        is_wm: row.is_wm,
        id: row.id
      }
      const { data, msg } = await ShopTempService.changeWm(param)
      if (data) {
        this.$message.success(msg)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.shop-img{
  width: 50px;
  height: 50px;
  object-fit: cover;
}
</style>
